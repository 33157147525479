import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";

type Props = {
  isLoggedIn?: boolean;
};

const CreateEndUserComponent: React.FC<Props> = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    postCrateEndUserData,
    endUserResponse,
    endUserError,
    endUserLoading,
    endUserMessage,
    endUserErrorCount,
  ] = useAxiosPost<EndUserProp>("endUsers/createEndUser");

  const [
    getMyAreasData,
    areasResponse,
    areasError,
    AreasLoading,
    areasMessage,
    areasErrorCount,
  ] = useAxiosPost<ResponseProp>("areas/getAllAreas");
  const [allAreas, setAllAreas] = useState<any[]>([]);

  useEffect(() => {
    getMyAreasData({});
  }, []);

  useEffect(() => {
    if (areasResponse !== null) {
      const { responseData } = areasResponse;
      setAllAreas((responseData as any) || []);
    }
  }, [areasResponse]);

  useEffect(() => {
    if (endUserError !== null && endUserErrorCount !== 0) {
      notifyError(endUserError);
      setIsSubmitting(false);
    }
  }, [endUserError, endUserErrorCount]);
  useEffect(() => {
    if (endUserResponse !== null) {
      const { message } = endUserResponse;
      if (message) {
        notifySuccess(message);
      }
      setTimeout(() => {
        window.location.href = "/all-end-users";
      }, 5000);
    }
  }, [endUserResponse]);

  const validationSchema = yup.object().shape({
    endUserPPPoEUserID: yup.string().required("PPPoE Username is required"),
    endUserPPPoEPassword: yup.string().required("PPPoE Password is required"),
    new_2_4G_SSID: yup.string().required("2G SSID is required"),
    new_2_4G_WifiKey: yup.string().required("2G Password is required"),
    new_5G_SSID: yup.string().when("perform5GWifiConfig", {
      is: true,
      then: (schema) => schema.required("5G SSID is required"),
    }),
    new_5G_WifiKey: yup.string().when("perform5GWifiConfig", {
      is: true,
      then: (schema) => schema.required("5G Password is required"),
    }),
    endUserFullName: yup.string().required("Full Name is required"),
    endUserAddress: yup.string().required("Address is required"),
    endUserPhoneNumber: yup.string().required("Contact is required"),
    intPkAreaID: yup.string().required("Area is required"),
  });

  return (
    <>
      <ToastContainer />
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col xs lg="8" md="8" className="bg-dark text-white p-5 rounded">
            <h3 className="mb-4 text-center">Create New End User</h3>
            <Formik
              initialValues={{
                endUserPPPoEUserID: "",
                endUserPPPoEPassword: "",
                isIpv6Enable: false,
                perform5GWifiConfig: false, // 5G checkbox (default unchecked)
                new_2_4G_SSID: "",
                new_2_4G_WifiKey: "",
                new_5G_SSID: "",
                new_5G_WifiKey: "",
                endUserFullName: "",
                endUserAddress: "",
                endUserPhoneNumber: "",
                intPkAreaID: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                setIsSubmitting(true);
                console.log({ values });
                postCrateEndUserData({
                  ...values,
                  new_5G_SSID: values.new_5G_SSID ? values.new_5G_SSID : null,
                  new_5G_WifiKey: values.new_5G_WifiKey
                    ? values.new_5G_WifiKey
                    : null,
                });
              }}
            >
              {({ values }) => (
                <Form>
                  {/* PPPoE Section */}
                  <Card className="mb-3">
                    <Card.Body>
                      <Card.Title>PPPoE Box</Card.Title>
                      <label>PPPoE Username</label>
                      <Field
                        name="endUserPPPoEUserID"
                        placeholder="PPPoE Username"
                        className="form-control mb-2"
                      />

                      <ErrorMessage
                        name="endUserPPPoEUserID"
                        component="div"
                        className="text-danger"
                      />

                      <label>PPPoE Password</label>

                      <Field
                        name="endUserPPPoEPassword"
                        type="text"
                        placeholder="PPPoE Password"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="endUserPPPoEPassword"
                        component="div"
                        className="text-danger"
                      />
                      {/* IPv6 Checkbox */}
                      <div className="form-check mt-2">
                        <Field
                          type="checkbox"
                          name="isIpv6Enable"
                          id="isIpv6Enable"
                          className="form-check-input"
                        />
                        <label
                          htmlFor="isIpv6Enable"
                          className="form-check-label"
                        >
                          IPv6 Enable?
                        </label>
                      </div>
                    </Card.Body>
                  </Card>

                  {/* WiFi Section */}
                  <Card className="mb-3">
                    <Card.Body>
                      <Card.Title>WiFi Details</Card.Title>
                      <label>2G SSD</label>

                      <Field
                        name="new_2_4G_SSID"
                        placeholder="2G SSID"
                        className="form-control mb-2"
                      />
                      <ErrorMessage
                        name="new_2_4G_SSID"
                        component="div"
                        className="text-danger"
                      />
                      <label>2G Password</label>

                      <Field
                        name="new_2_4G_WifiKey"
                        type="text"
                        placeholder="2G Password"
                        className="form-control mb-2"
                      />
                      <ErrorMessage
                        name="new_2_4G_WifiKey"
                        component="div"
                        className="text-danger"
                      />
                      {/* <Field
                        name="new_5G_SSID"
                        placeholder="5G SSID"
                        className="form-control mb-2"
                      />
                      <ErrorMessage
                        name="new_5G_SSID"
                        component="div"
                        className="text-danger"
                      />
                      <Field
                        name="new_5G_WifiKey"
                        type="password"
                        placeholder="5G Password"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="new_5G_WifiKey"
                        component="div"
                        className="text-danger"
                      /> */}
                      {/* 5G Config Checkbox */}
                      <div className="form-check mt-2">
                        <Field
                          type="checkbox"
                          name="perform5GWifiConfig"
                          id="perform5GWifiConfig"
                          className="form-check-input"
                        />
                        <label
                          htmlFor="perform5GWifiConfig"
                          className="form-check-label"
                        >
                          Enable 5G WiFi Configuration?
                        </label>
                      </div>

                      {/* Conditionally Render 5G Fields */}
                      {values.perform5GWifiConfig && (
                        <>
                          <label>5G SSID</label>

                          <Field
                            name="new_5G_SSID"
                            placeholder="5G SSID"
                            className="form-control mb-2"
                          />
                          <ErrorMessage
                            name="new_5G_SSID"
                            component="div"
                            className="text-danger"
                          />
                          <label>5G Password</label>

                          <Field
                            name="new_5G_WifiKey"
                            type="text"
                            placeholder="5G Password"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="new_5G_WifiKey"
                            component="div"
                            className="text-danger"
                          />
                        </>
                      )}
                    </Card.Body>
                  </Card>

                  {/* Personal Info Section */}
                  <Card className="mb-3">
                    <Card.Body>
                      <Card.Title>Personal Info</Card.Title>
                      <label>Full Name</label>

                      <Field
                        name="endUserFullName"
                        placeholder="Full Name"
                        className="form-control mb-2"
                      />
                      <ErrorMessage
                        name="endUserFullName"
                        component="div"
                        className="text-danger"
                      />
                      <label>Address</label>

                      <Field
                        name="endUserAddress"
                        placeholder="Address"
                        className="form-control mb-2"
                      />
                      <ErrorMessage
                        name="endUserAddress"
                        component="div"
                        className="text-danger"
                      />
                      <label>Contact</label>

                      <Field
                        name="endUserPhoneNumber"
                        placeholder="Contact"
                        className="form-control mb-2"
                      />

                      <ErrorMessage
                        name="endUserPhoneNumber"
                        component="div"
                        className="text-danger"
                      />
                      <label>Area</label>
                      <Field
                        as="select"
                        name="intPkAreaID"
                        className="form-control"
                      >
                        <option value="">Choose Area</option>
                        {allAreas.map((area) => (
                          <option
                            key={area.intPkAreaID}
                            value={area.intPkAreaID}
                          >
                            {area.areaName}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="intPkAreaID"
                        component="div"
                        className="text-danger"
                      />
                    </Card.Body>
                  </Card>

                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting || endUserLoading}
                  >
                    {isSubmitting || endUserLoading
                      ? "Submitting..."
                      : "Submit"}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateEndUserComponent;
