import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import Modal from "react-bootstrap/Modal";

const CreateEndUserModalComponent: React.FC<ShowModelForCreatingEndUser> = ({
  show,
  handleClose,
  createEndUser,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    postCrateEndUserData,
    endUserResponse,
    endUserError,
    endUserLoading,
    endUserMessage,
    endUserErrorCount,
  ] = useAxiosPost<EndUserProp>("endUsers/createEndUser");

  const [
    getMyAreasData,
    areasResponse,
    areasError,
    AreasLoading,
    areasMessage,
    areasErrorCount,
  ] = useAxiosPost<ResponseProp>("areas/getAllAreas");
  const [allAreas, setAllAreas] = useState<any[]>([]);

  useEffect(() => {
    getMyAreasData({});
  }, []);

  useEffect(() => {
    if (areasResponse !== null) {
      const { responseData } = areasResponse;
      setAllAreas((responseData as any) || []);
    }
  }, [areasResponse]);

  useEffect(() => {
    if (endUserError !== null && endUserErrorCount !== 0) {
      notifyError(endUserError);
      setIsSubmitting(false);
    }
  }, [endUserError, endUserErrorCount]);

  useEffect(() => {
    if (endUserResponse !== null) {
      const response: any = endUserResponse;
      const { message, responseData } = response;
      if (responseData.length > 0) {
        const data: any = responseData[0];
        const {
          areaName,
          dateCreated,
          endUserAddress,
          endUserFullName,
          endUserPhoneNumber,
          intPkAreaID,
          intPkEndUserID,
          intPkUserID,
        } = data;

        if (message) {
          notifySuccess(message);

          createEndUser(
            endUserFullName,
            endUserAddress,
            endUserPhoneNumber,
            intPkAreaID,
            intPkEndUserID
          );
          handleClose();
        }
      }
    }
  }, [endUserResponse]);

  const validationSchema = yup.object().shape({
    endUserPPPoEUserID: yup.string().required("PPPoE Username is required"),
    endUserPPPoEPassword: yup.string().required("PPPoE Password is required"),
    new_2_4G_SSID: yup.string().required("2G SSID is required"),
    new_2_4G_WifiKey: yup.string().required("2G Password is required"),
    new_5G_SSID: yup.string().when("perform5GWifiConfig", {
      is: true,
      then: (schema) => schema.required("5G SSID is required"),
    }),
    new_5G_WifiKey: yup.string().when("perform5GWifiConfig", {
      is: true,
      then: (schema) =>
        schema
          .min(1, "Password must be at least 1 characters")
          .required("5G Password is required"),
    }),
    endUserFullName: yup.string().required("Full Name is required"),
    endUserAddress: yup.string().required("Address is required"),
    endUserPhoneNumber: yup.string().required("Contact is required"),
    intPkAreaID: yup.string().required("Area is required"),
  });

  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create New End User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="justify-content-center">
              <Col xs lg="12" className="bg-dark text-white p-4 rounded">
                <h3 className="mb-4 text-center">Create New End User</h3>
                <Formik
                  initialValues={{
                    endUserPPPoEUserID: "",
                    endUserPPPoEPassword: "",
                    isIpv6Enable: false,
                    perform5GWifiConfig: false,
                    new_2_4G_SSID: "",
                    new_2_4G_WifiKey: "",
                    new_5G_SSID: "",
                    new_5G_WifiKey: "",
                    endUserFullName: "",
                    endUserAddress: "",
                    endUserPhoneNumber: "",
                    intPkAreaID: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    setIsSubmitting(true);
                    postCrateEndUserData({
                      ...values,
                      new_5G_SSID: values.new_5G_SSID
                        ? values.new_5G_SSID
                        : null,
                      new_5G_WifiKey: values.new_5G_WifiKey
                        ? values.new_5G_WifiKey
                        : null,
                    });
                  }}
                >
                  {({ values }) => (
                    <Form>
                      <Card className="mb-3">
                        <Card.Body>
                          <Card.Title>PPPoE Details</Card.Title>
                          <label>PPPoE Username</label>

                          <Field
                            name="endUserPPPoEUserID"
                            placeholder="PPPoE Username"
                            className="form-control mb-2"
                          />
                          <ErrorMessage
                            name="endUserPPPoEUserID"
                            component="div"
                            className="text-danger"
                          />
                          <label>PPPoE Password</label>

                          <Field
                            name="endUserPPPoEPassword"
                            type="text"
                            placeholder="PPPoE Password"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="endUserPPPoEPassword"
                            component="div"
                            className="text-danger"
                          />
                          <div className="form-check mt-2">
                            <Field
                              type="checkbox"
                              name="isIpv6Enable"
                              className="form-check-input"
                            />
                            <label className="form-check-label">
                              IPv6 Enable?
                            </label>
                          </div>
                        </Card.Body>
                      </Card>

                      <Card className="mb-3">
                        <Card.Body>
                          <Card.Title>WiFi Configuration</Card.Title>
                          <label>2G SSD</label>

                          <Field
                            name="new_2_4G_SSID"
                            placeholder="2G SSID"
                            className="form-control mb-2"
                          />
                          <ErrorMessage
                            name="new_2_4G_SSID"
                            component="div"
                            className="text-danger"
                          />
                          <label>2G Password</label>

                          <Field
                            name="new_2_4G_WifiKey"
                            type="text"
                            placeholder="2G Password"
                            className="form-control mb-2"
                          />
                          <ErrorMessage
                            name="new_2_4G_WifiKey"
                            component="div"
                            className="text-danger"
                          />
                          <div className="form-check mt-2">
                            <Field
                              type="checkbox"
                              name="perform5GWifiConfig"
                              className="form-check-input"
                            />
                            <label className="form-check-label">
                              Enable 5G WiFi?
                            </label>
                          </div>
                          {/* Conditionally Render 5G Fields */}
                          {values.perform5GWifiConfig && (
                            <>
                              <label>5G SSID</label>

                              <Field
                                name="new_5G_SSID"
                                placeholder="5G SSID"
                                className="form-control mb-2"
                              />
                              <ErrorMessage
                                name="new_5G_SSID"
                                component="div"
                                className="text-danger"
                              />
                              <label>5G Password</label>

                              <Field
                                name="new_5G_WifiKey"
                                type="text"
                                placeholder="5G Password"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="new_5G_WifiKey"
                                component="div"
                                className="text-danger"
                              />
                            </>
                          )}
                        </Card.Body>
                      </Card>
                      {/* Personal Info Section */}
                      <Card className="mb-3">
                        <Card.Body>
                          <Card.Title>Personal Info</Card.Title>
                          <label>Full Name</label>

                          <Field
                            name="endUserFullName"
                            placeholder="Full Name"
                            className="form-control mb-2"
                          />
                          <ErrorMessage
                            name="endUserFullName"
                            component="div"
                            className="text-danger"
                          />
                          <label>Address</label>

                          <Field
                            name="endUserAddress"
                            placeholder="Address"
                            className="form-control mb-2"
                          />
                          <ErrorMessage
                            name="endUserAddress"
                            component="div"
                            className="text-danger"
                          />
                          <label>Contact</label>

                          <Field
                            name="endUserPhoneNumber"
                            placeholder="Contact"
                            className="form-control mb-2"
                          />
                          <ErrorMessage
                            name="endUserPhoneNumber"
                            component="div"
                            className="text-danger"
                          />
                          <label>Area</label>

                          <Field
                            as="select"
                            name="intPkAreaID"
                            className="form-control"
                          >
                            <option value="">Choose Area</option>
                            {allAreas.map((area) => (
                              <option
                                key={area.intPkAreaID}
                                value={area.intPkAreaID}
                              >
                                {area.areaName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="intPkAreaID"
                            component="div"
                            className="text-danger"
                          />
                        </Card.Body>
                      </Card>

                      <Button
                        type="submit"
                        variant="primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateEndUserModalComponent;
