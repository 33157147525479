import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { ToastContainer } from "react-toastify";
const actionEnum: any = {
  FIND_ONT_BY_PON: "Find Ont Device ID",
  GET_ONT_BY_DEVICE_ID: "Device Details",
  RESET_ONT: "Reset Factory",
  INIT_CONFIGS: "Push configs",
  UPDATE_WIFI_KEY: "Update Wifi",
  PUSH_WAN_CONFIGS: "Push Wan configs",
};
const ViewRequestDetailsModal: React.FC<ModalForLogsRequestOfCapOnt> = ({
  show,
  handleClose,
  requestData,
}) => {
  // Function to format values
  const formatValue = (value: any) => {
    if (value === null) return "";
    if (value === 1) return "Yes";
    if (value === 0) return "No";
    return value;
  };

  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Request Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <tbody>
              {/* <tr>
                <td>
                  <strong>ID</strong>
                </td>
                <td>{requestData?.intPkCapOntActionQueueID}</td>
              </tr>
              <tr>
                <td>
                  <strong>OLT ID</strong>
                </td>
                <td>{requestData?.intPkOltID}</td>
              </tr> */}
              {/* <tr>
                <td>
                  <strong>ONT Registration ID</strong>
                </td>
                <td>{requestData?.intPkOntRegisterationID}</td>
              </tr> */}
              <tr>
                <td>
                  <strong>PON Number</strong>
                </td>
                <td>{requestData?.ponNumber}</td>
              </tr>
              {/* <tr>
                <td>
                  <strong>ONT Device ID</strong>
                </td>
                <td>{requestData?.ontDeviceID}</td>
              </tr> */}
              <tr>
                <td>
                  <strong>Username</strong>
                </td>
                <td>{formatValue(requestData?.username)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Password</strong>
                </td>
                <td>{formatValue(requestData?.password)}</td>
              </tr>
              <tr>
                <td>
                  <strong>VLAN</strong>
                </td>
                <td>{requestData?.vlan}</td>
              </tr>
              <tr>
                <td>
                  <strong>IPv6 Enabled</strong>
                </td>
                <td>{formatValue(requestData?.ipv6Enable)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Perform 2.4G WiFi Config</strong>
                </td>
                <td>{formatValue(requestData?.perform2_4GWifiConfig)}</td>
              </tr>
              <tr>
                <td>
                  <strong>New 2.4G SSID</strong>
                </td>
                <td>{formatValue(requestData?.new_2_4G_SSID)}</td>
              </tr>
              <tr>
                <td>
                  <strong>New 2.4G WiFi Key</strong>
                </td>
                <td>{requestData?.new_2_4G_WifiKey}</td>
              </tr>
              <tr>
                <td>
                  <strong>Perform 5G WiFi Config</strong>
                </td>
                <td>{formatValue(requestData?.perform5GWifiConfig)}</td>
              </tr>
              <tr>
                <td>
                  <strong>New 5G SSID</strong>
                </td>
                <td>{formatValue(requestData?.new_5G_SSID)}</td>
              </tr>
              <tr>
                <td>
                  <strong>New 5G WiFi Key</strong>
                </td>
                <td>{requestData?.new_5G_WifiKey}</td>
              </tr>
              <tr>
                <td>
                  <strong>Date Created</strong>
                </td>
                <td>{new Date(requestData?.dateCreated).toLocaleString()}</td>
              </tr>
              <tr>
                <td>
                  <strong>Action Type</strong>
                </td>
                <td>{actionEnum[requestData?.actionEnum] || ""}</td>
              </tr>
              <tr>
                <td>
                  <strong>Request Status</strong>
                </td>
                <td>{requestData?.requestStatus}</td>
              </tr>
              <tr>
                <td>
                  <strong>Request Update Date</strong>
                </td>
                <td>
                  {requestData?.requestUpdateStatusDate
                    ? new Date(
                        requestData?.requestUpdateStatusDate
                      ).toLocaleString()
                    : ""}{" "}
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewRequestDetailsModal;
