import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer } from "react-toastify";

import { Button, Card, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import TimeAgo from "react-timeago";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import "./tabs.css";
import moment from "moment";
import { formatDate } from "../../helpers/date";
import humanizeDuration from "humanize-duration";
import "./../../assets/styles/olt.css";
import "./../../assets/styles/ont-list.css";
//status

// Statics
import CopyButton from "./../../assets/svgs/copy/Copy.svg";
import PushConfigsOnInitCapontModal from "./PushConfigsOnInitCapontModal";
import ConfirmationOfFactoryModal from "./ConfirmationOfFactoryModal";
import UpdateWifiKeyModal from "./UpdateWifiKeyModal";
import ViewRequestDetailsModal from "./ViewRequestDetailsModal";
import ConnectedDevicesTable from "./ConnectedDevicesTable";
//block svgs
const actionEnum: any = {
  FIND_ONT_BY_PON: "Find Ont Device ID",
  GET_ONT_BY_DEVICE_ID: "Device Details",
  RESET_ONT: "Reset Factory",
  INIT_CONFIGS: "Push configs",
  UPDATE_WIFI_KEY: "Update Wifi",
  PUSH_WAN_CONFIGS: "Push Wan configs",
};
const Tabs: React.FC<any> = ({ activeTab, setActiveTab }) => {
  return (
    <div className="tabs-container">
      {/* Tab Buttons */}
      <div className="tabs">
        <button
          className={`tab-btn ${activeTab === "details" ? "active" : ""}`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </button>
        <button
          className={`tab-btn ${activeTab === "logs" ? "active" : ""}`}
          onClick={() => setActiveTab("logs")}
        >
          Logs
        </button>
      </div>
    </div>
  );
};

const BrowseCapontComponent: React.FC<GP> = ({}) => {
  const [activeTab, setActiveTab] = useState("details");

  const navigate = useNavigate();
  const [isBlinkingStart, setIsBlinkingStart] = useState<boolean>(false);
  const { intPkOntRegisterationID, intPkPonPortID, oltName, intPkOltID } =
    useParams();
  const [allOnts, setAllOnts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ontDetails, setOntDetails] = useState<any>(null);
  const [ontType, setOntType] = useState<number>(1);
  const [show, setShow] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [requestData, setRequestData] = useState<any>(null);
  const [showWifi, setShowWifi] = useState(false);
  const [showFactory, setShowFactory] = useState(false);
  const handleClose = () => {
    setShow(false);
    setShowWifi(false);
    setShowRequest(false);
    setRequestData(null);
  };
  const handleCloseFactory = (_confirm: boolean) => {
    setShowFactory(false);
    if (_confirm) {
      requestToFactoryResetOnt({
        intPkOntRegisterationID: Number(intPkOntRegisterationID),
      });
    }
  };

  const [
    getFoundOntsData,
    FoundOntsResponseData,
    foundOntsError,
    foundOntsLoading,
    foundOntsMessage,
    foundOntsErrorCount,
  ] = useAxiosPost<CapOntOntDetails>(`capont/getOntDetails`);
  const [
    requestToGetCapontDeviceId,
    deviceIdRequestResponse,
    deviceIdRequestError,
    deviceIdRequestLoading,
    deviceIdRequestMessage,
    deviceIdRequestErrorCount,
  ] = useAxiosPost<CapOntOntDetails>("capont/requestToGetOntDeviceId");

  const [
    requestToGetCapontDeviceDetails,
    deviceDetailsRequestResponse,
    deviceDetailsRequestError,
    deviceDetailsRequestLoading,
    deviceDetailsRequestMessage,
    deviceDetailsRequestErrorCount,
  ] = useAxiosPost<CapOntOntDetails>("capont/requestToGetOntDeviceDetails");

  const [
    requestToFactoryResetOnt,
    factoryResetRequestResponse,
    factoryResetRequestError,
    factoryResetRequestLoading,
    factoryResetRequestMessage,
    factoryResetRequestErrorCount,
  ] = useAxiosPost<CapOntOntDetails>("capont/resetFactory");

  useEffect(() => {
    if (
      factoryResetRequestError !== null &&
      factoryResetRequestErrorCount !== 0
    ) {
      notifyError(factoryResetRequestError);
    }
  }, [factoryResetRequestError, factoryResetRequestErrorCount]);
  useEffect(() => {
    if (factoryResetRequestResponse !== null) {
      const { message } = factoryResetRequestResponse;
      if (message) {
        notifySuccess(message);
      }
    }
  }, [factoryResetRequestResponse]);
  useEffect(() => {
    getFoundOntsData({
      intPkOntRegisterationID: Number(intPkOntRegisterationID),
    });
    const interval = setInterval(() => {
      getFoundOntsData({
        intPkOntRegisterationID: Number(intPkOntRegisterationID),
      });
    }, 3000);
    return () => {
      clearInterval(interval);
      console.log("Timer cleared on unmount");
    };
  }, [intPkOntRegisterationID]);

  useEffect(() => {
    if (deviceIdRequestError !== null && deviceIdRequestErrorCount !== 0) {
      notifyError(deviceIdRequestError);
    }
  }, [deviceIdRequestError, deviceIdRequestErrorCount]);
  useEffect(() => {
    if (deviceIdRequestResponse !== null) {
      const { message } = deviceIdRequestResponse;
      if (message) {
        notifySuccess(message);
      }
    }
  }, [deviceIdRequestResponse]);
  useEffect(() => {
    if (foundOntsError !== null && foundOntsErrorCount !== 0) {
      notifyError(foundOntsError);
    }
  }, [foundOntsError, foundOntsErrorCount]);
  useEffect(() => {
    if (FoundOntsResponseData !== null) {
      const responseData: any = FoundOntsResponseData.responseData;
      if (responseData) {
        const { isOntEponOrGpon, logs } = responseData;
        if (logs && logs?.length > 0) {
          const latest = logs[0];
          if (latest.requestStatus === "PENDING") {
            setIsBlinkingStart(true);
          } else {
            setIsBlinkingStart(false);
          }
        } else {
          setIsBlinkingStart(false);
        }
        // console.log({ isOntEponOrGpon });
        setOntType(Number(isOntEponOrGpon));
        setOntDetails(responseData);
      }
    }
  }, [FoundOntsResponseData]);
  useEffect(() => {
    if (
      deviceDetailsRequestError !== null &&
      deviceDetailsRequestErrorCount !== 0
    ) {
      notifyError(deviceDetailsRequestError);
    }
  }, [deviceDetailsRequestError, deviceDetailsRequestErrorCount]);
  useEffect(() => {
    if (deviceDetailsRequestResponse !== null) {
      const { message } = deviceDetailsRequestResponse;
      if (message) {
        notifySuccess(message);
      }
    }
  }, [deviceDetailsRequestResponse]);
  const convertMetersToKiloMeters = (meters: any) => {
    if (meters < 1000) {
      return `${meters} Meters`;
    } else {
      return `${meters / 1000} KM`;
    }
  };
  const handleRequestDeviceId = () => {
    requestToGetCapontDeviceId({
      intPkOntRegisterationID: Number(intPkOntRegisterationID),
    });
  };
  const getTimeAgo = (_date: any) => {
    const startTime = moment(_date);
    const currentTime = moment();
    // <TimeAgo date={new Date(_date)} />;
    return (
      <TimeAgo date={new Date(_date)} />
      // <Moment from={currentTime} ago>
      //   {startTime}
      // </Moment>
    );
  };

  const getStatusString = (status: any) => {
    if (status === "UP") {
      return "Up Since";
    }
    if (status === "DOWN") {
      return "Down Since";
    }
    if (status === "UPSET") {
      return "Upset Since";
    }
  };

  const showPonOrMacAsciiPon = (
    ontType: number,
    mac: any,
    pon: any,
    asciiPon: any
  ) => {
    if (ontType === 1) {
      return (
        <Col className="mt-1" md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
          <span className="data-r-label">MacAddress</span> :{" "}
          <span className="data-r-field">{mac}</span>{" "}
          <CopyToClipboard
            text={mac || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
            }}
          >
            <img
              src={CopyButton}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: "pointer" }}
            />
          </CopyToClipboard>
        </Col>
      );
    }
    if (ontType === 2) {
      return (
        <Col className="mt-1" md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
          <span className="data-r-label">Pon</span> :{" "}
          <span className="data-r-field">{pon}</span>{" "}
          <CopyToClipboard
            text={pon || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
            }}
          >
            <img
              src={CopyButton}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: "pointer" }}
            />
          </CopyToClipboard>
        </Col>
      );
    }
    if (ontType === 3) {
      return (
        <Col className="mt-1" md={12} lg={12} xl={12} xxl={12} sm={12} xs={12}>
          <span className="data-r-label">Pon</span> :{" "}
          <span className="data-r-field">{asciiPon}</span>{" "}
          <CopyToClipboard
            text={asciiPon || ""}
            onCopy={() => {
              notifySuccess(`Copied To Clipboard`);
            }}
          >
            <img
              src={CopyButton}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ cursor: "pointer" }}
            />
          </CopyToClipboard>
        </Col>
      );
    }
  };

  return (
    <>
      <ToastContainer />

      {ontDetails !== null && (
        <>
          {show === true && ontDetails.endUser !== null && (
            <PushConfigsOnInitCapontModal
              show={show}
              handleClose={handleClose}
              endUser={ontDetails.endUser}
              intPkOntRegisterationID={Number(intPkOntRegisterationID)}
              isOntPPPoEOrBridge={Number(ontDetails.isOntPPPoEOrBridge)}
            />
          )}
          {showWifi === true && ontDetails.endUser !== null && (
            <UpdateWifiKeyModal
              show={showWifi}
              handleClose={handleClose}
              endUser={ontDetails.endUser}
              intPkOntRegisterationID={Number(intPkOntRegisterationID)}
              isOntPPPoEOrBridge={Number(ontDetails.isOntPPPoEOrBridge)}
            />
          )}

          {showFactory === true && ontDetails.endUser !== null && (
            <ConfirmationOfFactoryModal
              show={showFactory}
              handleClose={handleCloseFactory}
            />
          )}
          {showRequest === true && requestData !== null && (
            <ViewRequestDetailsModal
              show={showRequest}
              handleClose={handleClose}
              requestData={requestData}
            />
          )}
          <Container className="mt-5">
            <Row className="justify-content-center">
              <Col xs lg="12" md="12" xl="12" className="p-5">
                <Card className="p-5">
                  <Card.Title className="text-center olt-heading pt-3 main-ont-heading">
                    {" "}
                    {ontDetails.oltName}{" "}
                    {ontDetails.ontDeviceID !== null ? (
                      <span>
                        <Button
                          onClick={(e) => {
                            requestToGetCapontDeviceDetails({
                              intPkOntRegisterationID: Number(
                                intPkOntRegisterationID
                              ),
                            });
                            setIsBlinkingStart(true);
                          }}
                          style={{ width: "200px", height: "50px" }}
                          disabled={deviceIdRequestLoading || isBlinkingStart}
                          className={`${
                            isBlinkingStart === true
                              ? "blinking-image image-float-right"
                              : "image-float-right"
                          }`}
                        >
                          {deviceIdRequestLoading
                            ? "Loading"
                            : "ReLoad Details"}
                        </Button>
                      </span>
                    ) : (
                      <span>
                        <Button
                          onClick={(e) => {
                            handleRequestDeviceId();
                          }}
                          style={{ width: "200px", height: "50px" }}
                          disabled={deviceIdRequestLoading}
                          className="image-float-right"
                        >
                          {deviceIdRequestLoading
                            ? "Loading"
                            : "Load Device ID"}
                        </Button>
                      </span>
                    )}
                  </Card.Title>
                  <Card.Body>
                    <Row className="single-ont-container-extended">
                      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
                      {activeTab === "details" ? (
                        <>
                          <Col
                            className="mt-3"
                            md={7}
                            lg={7}
                            xl={7}
                            xxl={7}
                            sm={12}
                            xs={12}
                          >
                            <span className="data-r-label">OLT</span> :{" "}
                            <span className="data-r-field">
                              {ontDetails.oltName}
                            </span>
                          </Col>
                          <Col
                            className="mt-2"
                            md={5}
                            lg={5}
                            xl={5}
                            xxl={5}
                            sm={12}
                            xs={12}
                          >
                            {ontType === 2 ? (
                              <>
                                <span className="image-float-left">
                                  <span className="data-r-label">FSP</span> :{" "}
                                  <span className="data-r-field">
                                    {ontDetails.fsp}
                                  </span>
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="image-float-left">
                                  <span className="data-r-label">Id</span> :{" "}
                                  <span className="data-r-field">
                                    {ontDetails.fspId}
                                  </span>
                                </span>
                              </>
                            )}
                            <span className="image-float-right">
                              <span className="data-r-label">ONT ID</span> :{" "}
                              <span className="data-r-field">
                                {ontDetails.ontID}
                              </span>
                            </span>
                          </Col>
                          {showPonOrMacAsciiPon(
                            ontType,
                            ontDetails.formattedOntEponMacAddressID,
                            ontDetails.ponNumber,
                            ontDetails.asciiHexaPonNumber
                          )}

                          {ontDetails.ontDeviceID !== null &&
                            ontDetails.onDeviceDetails && (
                              <>
                                <Row>
                                  {/* Manufacturer */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      Manufacturer
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.ontDeviceInfoObj?.manufacturer ||
                                        "N/A"}
                                    </span>
                                  </Col>

                                  {/* Model */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">Model</span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.ontDeviceInfoObj?.productClass ||
                                        "N/A"}
                                    </span>
                                  </Col>

                                  {/* HW Serial # */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      HW Serial #
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.ontDeviceInfoObj?.hwSerialNumber ||
                                        "N/A"}
                                    </span>
                                  </Col>

                                  {/* SW Version */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      SW Version
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.ontDeviceInfoObj?.ontSWVersion ||
                                        "N/A"}
                                    </span>
                                  </Col>

                                  {/* LAN IP */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">LAN IP</span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.ontDeviceInfoObj?.ontLanIPAddress ||
                                        "N/A"}
                                    </span>
                                  </Col>

                                  {/* PowerON Uptime */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      PowerON Uptime
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.ontDeviceInfoObj?.uptimeSeconds
                                        ? humanizeDuration(
                                            ontDetails.onDeviceDetails
                                              .ontDeviceInfoObj.uptimeSeconds *
                                              1000
                                          )
                                        : "N/A"}
                                    </span>
                                  </Col>

                                  {/* WAN IP */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">WAN IP</span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.pppoeWanInterfaceObj
                                        ?.PPPOE_WAN_IPv4 || "N/A"}
                                    </span>
                                  </Col>
                                  {/* Connected Duration  */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      Connected Duration{" "}
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.pppoeWanInterfaceObj
                                        ?.PPPoE_UptimeSeconds
                                        ? humanizeDuration(
                                            ontDetails?.onDeviceDetails
                                              ?.pppoeWanInterfaceObj
                                              ?.PPPoE_UptimeSeconds * 1000
                                          )
                                        : "N/A"}
                                    </span>
                                  </Col>

                                  {/* PPPoE Username */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      PPPoE Username
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.pppoeWanInterfaceObj
                                        ?.PPPoE_Username || "N/A"}
                                    </span>
                                  </Col>

                                  {/* Connection Status */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      Connection Status
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.pppoeWanInterfaceObj
                                        ?.PPPoE_ConnectionStatus || "N/A"}
                                    </span>
                                  </Col>

                                  {/* MAC Address */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      MAC Address
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.pppoeWanInterfaceObj?.PPPoE_MAC ||
                                        "N/A"}
                                    </span>
                                  </Col>

                                  {/* VLAN */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">VLAN</span> :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.pppoeWanInterfaceObj?.PPPoE_VLAN ||
                                        "N/A"}
                                    </span>
                                  </Col>

                                  {/* IPv6 Enable */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      IPv6 Enable
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails
                                        ?.pppoeWanInterfaceObj?.PPPoE_IPv6Enable
                                        ? "Yes"
                                        : "No"}
                                    </span>
                                  </Col>

                                  {/* 2.4G Enable */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      2.4G Enable
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails?.ontWifiObj
                                        ?.wifi2GEnable
                                        ? "Yes"
                                        : "No"}
                                    </span>
                                  </Col>

                                  {/* 2.4G SSID */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      2.4G SSID
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails?.ontWifiObj
                                        ?.wifi2GSSID || "N/A"}
                                    </span>
                                  </Col>

                                  {/* 5G Enable */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      5G Enable
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails?.ontWifiObj
                                        ?.wifi5GEnable
                                        ? "Yes"
                                        : "No"}
                                    </span>
                                  </Col>

                                  {/* 5G SSID */}
                                  <Col
                                    className="mt-3"
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    sm={12}
                                    xs={12}
                                  >
                                    <span className="data-r-label">
                                      5G SSID
                                    </span>{" "}
                                    :{" "}
                                    <span className="data-r-field">
                                      {ontDetails?.onDeviceDetails?.ontWifiObj
                                        ?.wifi5GSSID || "N/A"}
                                    </span>
                                  </Col>
                                </Row>
                                {ontDetails?.onDeviceDetails?.ontDeviceInfoObj?.manufacturer
                                  ?.toLowerCase()
                                  .includes("huawei") && (
                                  <Col
                                    className="mt-3"
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    sm={12}
                                    xs={12}
                                  >
                                    <h3>Controls</h3>

                                    <div
                                      style={{ display: "flex", gap: "20px" }}
                                    >
                                      <Button
                                        className={
                                          isBlinkingStart === true
                                            ? "blinking-image"
                                            : ""
                                        }
                                        disabled={
                                          !ontDetails.endUser || isBlinkingStart
                                        }
                                        variant="warning"
                                        onClick={(e) => setShow(true)}
                                      >
                                        Push Configs
                                      </Button>
                                      <Button
                                        className={
                                          isBlinkingStart === true
                                            ? "blinking-image"
                                            : ""
                                        }
                                        disabled={
                                          !ontDetails.endUser || isBlinkingStart
                                        }
                                        variant="danger"
                                        onClick={(e) => setShowFactory(true)}
                                      >
                                        Reset Factory Settings
                                      </Button>

                                      <Button
                                        className={
                                          isBlinkingStart === true
                                            ? "blinking-image"
                                            : ""
                                        }
                                        disabled={
                                          !ontDetails.endUser || isBlinkingStart
                                        }
                                        variant="warning"
                                        onClick={(e) => setShowWifi(true)}
                                      >
                                        Update Wifi Keys
                                      </Button>
                                    </div>
                                  </Col>
                                )}
                                <Col
                                  className="mt-3"
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  xxl={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <ConnectedDevicesTable
                                    devices={
                                      ontDetails?.onDeviceDetails
                                        ?.enduserHostsConnected || []
                                    }
                                  />
                                </Col>
                              </>
                            )}
                        </>
                      ) : (
                        <>
                          <Col
                            className="mt-3"
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            sm={12}
                            xs={12}
                          >
                            <Table
                              style={{ width: "100%" }}
                              striped
                              bordered
                              hover
                              responsive
                            >
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Status</th>
                                  <th>Created</th>
                                  <th>View Request</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ontDetails.logs.map(
                                  (row: any, index: number) => (
                                    <tr key={index}>
                                      <td>
                                        {actionEnum[row.actionEnum] || ""}
                                      </td>
                                      <td>{row.requestStatus}</td>
                                      <td>{formatDate(row.dateCreated)}</td>
                                      <td>
                                        <Button
                                          onClick={(e) => {
                                            setShowRequest(true);
                                            setRequestData(row);
                                          }}
                                          variant="primary"
                                        >
                                          View Request
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          {/* 
          <Container className="mt-5">
            <Row className="justify-content-center">
              <Col xs lg="12" md="12" xl="12" className="p-5">
                <Card className="p-5">
                  <Card.Title className="text-center olt-heading pt-3 main-ont-heading">
                    {" "}
                    {ontDetails.oltName}{" "}
                  </Card.Title>
                  <Card.Body>
                    <Row
                      style={{
                        minHeight: "300px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="single-ont-container-extended"
                    >
                      <Button
                        onClick={(e) => {
                          handleRequestDeviceId();
                        }}
                        style={{ width: "200px", height: "50px" }}
                        disabled={deviceIdRequestLoading}
                      >
                        {deviceIdRequestLoading ? "Loading" : "Load Device ID"}
                      </Button>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container> */}
        </>
      )}
    </>
  );
};
export default BrowseCapontComponent;
