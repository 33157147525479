import React from "react";
import { Table, Container } from "react-bootstrap";

const ConnectedDevicesTable: React.FC<any> = ({ devices }) => {
  return (
    <Container className="mt-4">
      <h4>Connected Devices</h4>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>IP Address</th>
            <th>MAC Address</th>
            <th>Host Name</th>
            <th>Active</th>
            <th>Type</th>
            <th>LAN Port</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device: any, index: number) => (
            <tr key={index}>
              <td>{device.connectedLANDevices}</td>
              <td>{device.macAddress}</td>
              <td>{device.name || "Unknown"}</td>
              <td>{device.active ? "Yes" : "No"}</td>
              <td>{device.InterfaceType === "Ethernet" ? "LAN" : "WiFi"}</td>
              <td>
                {device.InterfaceType === "Ethernet"
                  ? device.Layer2Interface
                  : "---"}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};
export default ConnectedDevicesTable;
