import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import BSForm from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import Modal from "react-bootstrap/Modal";
const PushIntConfigs: React.FC<any> = ({
  show,
  handleClose,
  endUser,
  intPkOntRegisterationID,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    postCrateEndUserData,
    endUserResponse,
    endUserError,
    endUserLoading,
    endUserMessage,
    endUserErrorCount,
  ] = useAxiosPost<PushEndUserConfig>("capont/pushConfigsOnInit");

  useEffect(() => {
    if (endUserError !== null && endUserErrorCount !== 0) {
      notifyError(endUserError);
      setIsSubmitting(false);
    }
  }, [endUserError, endUserErrorCount]);

  useEffect(() => {
    if (endUserResponse !== null) {
      const response: any = endUserResponse;
      const { message } = response;
      if (message) {
        notifySuccess(message);

        handleClose();
      }
    }
  }, [endUserResponse]);

  const validationSchema = yup.object().shape({
    endUserPPPoEUserID: yup.string().required("PPPoE Username is required"),
    endUserPPPoEPassword: yup.string().required("PPPoE Password is required"),
    new_2_4G_SSID: yup.string().required("2G SSID is required"),
    new_2_4G_WifiKey: yup.string().required("2G Password is required"),
    new_5G_SSID: yup.string().when("perform5GWifiConfig", {
      is: true,
      then: (schema) => schema.required("5G SSID is required"),
    }),
    new_5G_WifiKey: yup.string().when("perform5GWifiConfig", {
      is: true,
      then: (schema) => schema.required("5G Password is required"),
    }),
  });

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs lg="12" className="bg-dark text-white p-4 rounded">
          <h3 className="mb-4 text-center">Push Configs</h3>
          <Formik
            initialValues={{
              endUserPPPoEUserID: endUser.endUserPPPoEUserID,
              endUserPPPoEPassword: endUser.endUserPPPoEPassword,
              isIpv6Enable: Boolean(endUser.isIpv6Enable),
              perform5GWifiConfig: Boolean(endUser.perform5GWifiConfig),
              new_2_4G_SSID: endUser.new_2_4G_SSID,
              new_2_4G_WifiKey: endUser.new_2_4G_WifiKey,
              new_5G_SSID: endUser.new_5G_SSID ? endUser.new_5G_SSID : "",
              new_5G_WifiKey: endUser.new_5G_WifiKey
                ? endUser.new_5G_WifiKey
                : "", //endUser.new_5G_WifiKey || "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setIsSubmitting(true);
              postCrateEndUserData({
                ...values,
                new_5G_SSID: values.new_5G_SSID ? values.new_5G_SSID : null,
                new_5G_WifiKey: values.new_5G_WifiKey
                  ? values.new_5G_WifiKey
                  : null,
                intPkOntRegisterationID: intPkOntRegisterationID,
                intPkEndUserID: endUser.intPkEndUserID,
              });
            }}
          >
            {({ values }) => (
              <Form>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>PPPoE Details</Card.Title>
                    <label>PPPoE Username</label>

                    <Field
                      name="endUserPPPoEUserID"
                      placeholder="PPPoE Username"
                      className="form-control mb-2"
                    />
                    <ErrorMessage
                      name="endUserPPPoEUserID"
                      component="div"
                      className="text-danger"
                    />
                    <label>PPPoE Password</label>

                    <Field
                      name="endUserPPPoEPassword"
                      type="text"
                      placeholder="PPPoE Password"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="endUserPPPoEPassword"
                      component="div"
                      className="text-danger"
                    />
                    <div className="form-check mt-2">
                      <Field
                        type="checkbox"
                        name="isIpv6Enable"
                        className="form-check-input"
                      />
                      <label className="form-check-label">IPv6 Enable?</label>
                    </div>
                  </Card.Body>
                </Card>

                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>WiFi Configuration</Card.Title>
                    <label>2G SSD</label>

                    <Field
                      name="new_2_4G_SSID"
                      placeholder="2G SSID"
                      className="form-control mb-2"
                    />
                    <ErrorMessage
                      name="new_2_4G_SSID"
                      component="div"
                      className="text-danger"
                    />
                    <label>2G Password</label>

                    <Field
                      name="new_2_4G_WifiKey"
                      type="text"
                      placeholder="2G Password"
                      className="form-control mb-2"
                    />
                    <ErrorMessage
                      name="new_2_4G_WifiKey"
                      component="div"
                      className="text-danger"
                    />
                    <div className="form-check mt-2">
                      <Field
                        type="checkbox"
                        name="perform5GWifiConfig"
                        className="form-check-input"
                      />
                      <label className="form-check-label">
                        Enable 5G WiFi?
                      </label>
                    </div>
                    {/* Conditionally Render 5G Fields */}
                    {values.perform5GWifiConfig && (
                      <>
                        <label>5G SSID</label>

                        <Field
                          name="new_5G_SSID"
                          placeholder="5G SSID"
                          className="form-control mb-2"
                        />
                        <ErrorMessage
                          name="new_5G_SSID"
                          component="div"
                          className="text-danger"
                        />

                        <label>5G Password</label>

                        <Field
                          name="new_5G_WifiKey"
                          type="text"
                          placeholder="5G Password"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="new_5G_WifiKey"
                          component="div"
                          className="text-danger"
                        />
                      </>
                    )}
                  </Card.Body>
                </Card>
                {/* Personal Info Section */}

                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

const PushWanConfigs: React.FC<any> = ({
  show,
  handleClose,
  endUser,
  intPkOntRegisterationID,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    postCrateEndUserData,
    endUserResponse,
    endUserError,
    endUserLoading,
    endUserMessage,
    endUserErrorCount,
  ] = useAxiosPost<PushEndUserConfig>("capont/pushWanConfigs");

  useEffect(() => {
    if (endUserError !== null && endUserErrorCount !== 0) {
      notifyError(endUserError);
      setIsSubmitting(false);
    }
  }, [endUserError, endUserErrorCount]);

  useEffect(() => {
    if (endUserResponse !== null) {
      const response: any = endUserResponse;
      const { message } = response;
      if (message) {
        notifySuccess(message);

        handleClose();
      }
    }
  }, [endUserResponse]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs lg="12" className="bg-dark text-white p-4 rounded">
          <h3 className="mb-4 text-center">Push Wan Configs</h3>
          <Button
            variant="primary"
            disabled={isSubmitting}
            onClick={(e) => {
              postCrateEndUserData({ intPkOntRegisterationID });
            }}
          >
            Push Configs
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
const PushConfigsOnInitCapontModal: React.FC<ModalForPushingConfigsOnInit> = ({
  show,
  handleClose,
  endUser,
  intPkOntRegisterationID,
  isOntPPPoEOrBridge,
}) => {
  const [selectedComponent, setSelectedComponent] = useState(
    Number(isOntPPPoEOrBridge) === 2 ? "bridge" : "pppoe"
  );

  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Push Configs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <BSForm>
                <BSForm.Check
                  type="radio"
                  label="Show Bridge"
                  name="componentSelect"
                  value="bridge"
                  checked={selectedComponent === "bridge"}
                  onChange={(e) => setSelectedComponent(e.target.value)}
                />
                <BSForm.Check
                  type="radio"
                  label="Show PPPoE"
                  name="componentSelect"
                  value="pppoe"
                  checked={selectedComponent === "pppoe"}
                  onChange={(e) => setSelectedComponent(e.target.value)}
                />
              </BSForm>
            </Col>
          </Row>
          {selectedComponent === "bridge" ? (
            <PushWanConfigs
              show={show}
              handleClose={handleClose}
              endUser={endUser}
              intPkOntRegisterationID={intPkOntRegisterationID}
            />
          ) : (
            <PushIntConfigs
              show={show}
              handleClose={handleClose}
              endUser={endUser}
              intPkOntRegisterationID={intPkOntRegisterationID}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PushConfigsOnInitCapontModal;
