import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer } from "react-toastify";

const ConfirmationOfFactoryModal: React.FC<ModalForResetFactoryOfOnt> = ({
  show,
  handleClose,
}) => {
  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={() => handleClose(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Factory Reset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to factory reset this ONT?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={() => handleClose(true)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationOfFactoryModal;
