import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify";
import useAxiosPost from "../../hooks/useAxiosPost";
import { notifyError } from "../toast/Error";
import { notifySuccess } from "../toast/Success";
import Modal from "react-bootstrap/Modal";

const UpdateWifiKeyModal: React.FC<ModalForPushingConfigsOnInit> = ({
  show,
  handleClose,
  endUser,
  intPkOntRegisterationID,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    postCrateEndUserData,
    endUserResponse,
    endUserError,
    endUserLoading,
    endUserMessage,
    endUserErrorCount,
  ] = useAxiosPost<PushEndUserConfig>("capont/updateOntWifiKeys");

  useEffect(() => {
    if (endUserError !== null && endUserErrorCount !== 0) {
      notifyError(endUserError);
      setIsSubmitting(false);
    }
  }, [endUserError, endUserErrorCount]);

  useEffect(() => {
    if (endUserResponse !== null) {
      const response: any = endUserResponse;
      const { message } = response;
      if (message) {
        notifySuccess(message);

        handleClose();
      }
    }
  }, [endUserResponse]);

  const validationSchema = yup.object().shape({
    new_2_4G_WifiKey: yup.string().required("2G Password is required"),

    new_5G_WifiKey: yup.string().when("perform5GWifiConfig", {
      is: true,
      then: (schema) => schema.required("5G Password is required"),
    }),
  });

  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Wifi Keys</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="justify-content-center">
              <Col xs lg="12" className="bg-dark text-white p-4 rounded">
                <h3 className="mb-4 text-center">Update Wifi Keys</h3>
                <Formik
                  initialValues={{
                    perform5GWifiConfig: Boolean(endUser.perform5GWifiConfig),
                    new_2_4G_WifiKey: endUser.new_2_4G_WifiKey,
                    new_5G_WifiKey: endUser.new_5G_WifiKey
                      ? endUser.new_5G_WifiKey
                      : "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    setIsSubmitting(true);
                    postCrateEndUserData({
                      ...values,
                      new_5G_WifiKey: values.new_5G_WifiKey
                        ? values.new_5G_WifiKey
                        : null,
                      intPkOntRegisterationID: intPkOntRegisterationID,
                      intPkEndUserID: endUser.intPkEndUserID,
                    });
                  }}
                >
                  {({ values }) => (
                    <Form>
                      <Card className="mb-3">
                        <Card.Body>
                          <Card.Title>WiFi Configuration</Card.Title>
                          <label>2G Password</label>
                          <Field
                            name="new_2_4G_WifiKey"
                            type="text"
                            placeholder="2G Password"
                            className="form-control mb-2"
                          />
                          <ErrorMessage
                            name="new_2_4G_WifiKey"
                            component="div"
                            className="text-danger"
                          />
                          <div className="form-check mt-2">
                            <Field
                              type="checkbox"
                              name="perform5GWifiConfig"
                              className="form-check-input"
                            />
                            <label className="form-check-label">
                              Enable 5G WiFi?
                            </label>
                          </div>
                          {/* Conditionally Render 5G Fields */}
                          {values.perform5GWifiConfig && (
                            <>
                              <label>5G Password</label>

                              <Field
                                name="new_5G_WifiKey"
                                type="text"
                                placeholder="5G Password"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="new_5G_WifiKey"
                                component="div"
                                className="text-danger"
                              />
                            </>
                          )}
                        </Card.Body>
                      </Card>
                      {/* Personal Info Section */}

                      <Button
                        type="submit"
                        variant="primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateWifiKeyModal;
